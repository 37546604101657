import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { FULL_HEIGHT } from '../../global-constants/sizes';

const DefaultPolicy: React.FC = () => {
  return (
    <Box p={5} minH={FULL_HEIGHT} display="flex" alignItems="flex-start" justifyContent="center">
      <VStack w="70%" h="100%" p={20} display="flex" alignItems="flex-start" justifyContent="flex-start">
        <Heading as="h1" mb={4}>
          Privacy Policy
        </Heading>
        <Text mb={4}>
          Your privacy is important to us. This privacy policy document outlines the types of personal information that is received and
          collected by our application and how it is used.
        </Text>
        <Heading as="h2" size="md" mb={2}>
          Information Collection
        </Heading>
        <Text mb={4}>
          We do not collect any personal information from our users. No data is collected, stored, or shared by our application.
        </Text>

        <Heading as="h2" size="md" mb={2}>
          Cookies and Web Beacons
        </Heading>
        <Text mb={4}>Our application does not use cookies.</Text>
        <Heading as="h2" size="md" mb={2}>
          Consent
        </Heading>
        <Text mb={4}>By using our application, you hereby consent to our privacy policy and agree to its terms.</Text>
        <Heading as="h2" size="md" mb={2}>
          Changes to our Privacy Policy
        </Heading>
        <Text mb={4}>If we decide to change our privacy policy, we will post those changes on this page.</Text>
        <Text>This policy was last modified on Sep 2024.</Text>
      </VStack>
    </Box>
  );
};

export default DefaultPolicy;
