import { Box, Button, Input, Text, Textarea, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FULL_HEIGHT, FULL_WIDTH } from '../../global-constants/sizes';

const Support: React.FC = () => {
  let path = useLocation().pathname;
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const appName =
    path
      .split('/')
      .pop()
      ?.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') || '';

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const mailtoLink = `mailto:support@example.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      `App Name: ${appName}\n\nMessage: ${message}`
    )}`;
    window.location.href = mailtoLink;
  };

  console.log('appName');

  return (
    <Box p={4} minH={FULL_HEIGHT}>
      <Box w={FULL_WIDTH * 0.5} height={FULL_WIDTH * 0.4} bg={useColorModeValue('gray.100', 'gray.900')} boxShadow="md" padding={10}>
        <Text fontSize="4xl" fontWeight="bold" mb={4} textAlign="center">
          Contact Us
        </Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <Box w="100%">
              <label htmlFor="appName" style={{ paddingLeft: 5, color: 'gray', fontSize: 14 }}>
                App Name:
              </label>
              <Input type="text" id="appName" value={appName} required w="100%" bg="white" disabled />
            </Box>
            <Box w="100%">
              <label htmlFor="subject" style={{ paddingLeft: 5, color: 'gray', fontSize: 14 }}>
                Subject:
              </label>
              <Input
                type="text"
                id="subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                required
                w="100%"
                bg="white"
                placeholder="Enter subject"
              />
            </Box>
            <Box w="100%">
              <label htmlFor="message" style={{ paddingLeft: 5, color: 'gray', fontSize: 14 }}>
                Message:
              </label>
              <Textarea
                id="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                required
                w="100%"
                bg="white"
                height="150px"
                placeholder="Enter your message here"
              />
            </Box>
            <Button type="submit" colorScheme="blue" mt={10}>
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default Support;
